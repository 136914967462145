import React, { CSSProperties, useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Container, Row, Col } from 'react-bootstrap';
import { withStyles } from '@material-ui/core/styles';
import { sortBy } from '../../db/products';
import { color, images, palette, spacing } from '../../Layouts/theme';
import { graphql, Link, PageProps } from 'gatsby';
import { Icon } from 'react-icons-kit';
import { androidClose } from 'react-icons-kit/ionicons/androidClose';
import {
  MOBILE_HEADER_TH,
  MOBILE_HEADER_EN,
  HEADER_TH,
  HEADER_EN,
  TITLE_2_TH,
  TITLE_2_EN,
  BODY_3_TH,
  BODY_3_EN,
  BODY_6_TH,
  BODY_6_EN,
  BODY_7_TH,
  BODY_7_EN,
  BUTTON_STYLE,
  NON_PADDING_LEFT_RIGHT,
  SpaceBetweenContent,
  BODY_2_TH,
  BODY_2_EN,
} from '../../Layouts/styled/main';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Button, ButtonGroup } from '@material-ui/core';
import EndingContent from '../../Layouts/Footer/ending-content';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import IconButton from '@material-ui/core/IconButton';
import { useMst } from '../../models/root-store';
import { getSourceImage } from '../../utils/image-sharp';
import { CardCaption } from '@/components/card-caption/card-caption';
import { ProductStore } from '@/models/product-store';

interface ProductProps { }

interface GraphqlProps {
  edges: any;
}

interface SortByProps {
  id: number;
  name_th: string;
  name_en: string;
}

const LINE: CSSProperties = {
  width: '100%',
  borderBottom: `1.5px solid ${color.mainColor4}`,
};

const ROW_CARD_BOTTOM: CSSProperties = {
  paddingBottom: 40,
};

const PRODUCT_DESCRIPTION: CSSProperties = {
  paddingTop: spacing[3],
};

const BOTTOM_DESCRIPTION: CSSProperties = {
  display: 'flex',
  alignItems: 'flex-end',
};

const ARROW: CSSProperties = {
  color: color.mainColor4,
  position: 'absolute',
  bottom: 0,
  right: 15,
  zIndex: 999,
};

const BUTTON_CONTAINER: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  marginTop: spacing[7] - 30,
};

const LINK_TO: CSSProperties = {
  textDecoration: 'none',
  color: color.textBlack,
  display: 'flex',
  flexDirection: 'column',
  height: '100%'
};

const AccordionDetail: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  // position: 'absolute',
  // zIndex: 999,
  backgroundColor: color.backgroundPrimary,
};

const subStringSoryBy = (name: string) =>
  name
    .toUpperCase()
    .split('BY ')
    .filter((x) => x)[0];

const Product: React.FC<PageProps<{ allFile: GraphqlProps }>> = observer(
  ({ data }) => {
    const { t } = useTranslation();
    const { ouayUnStore } = useMst();
    const [selectedSortBy, setSelectedSortBy] = useState<SortByProps>(null);
    const [selectedMenu, setSelectedMenu] = useState<number>(null);
    const [menu, setMenu] = useState([]);
    const [filterProducts, setFilterProducts] = useState([]);
    const [mobileLoadMore, setMobileLoadMore] = useState(6);
    const [products, setProducts] = useState([]);
    const [endOfProduct, setEndOfProduct] = useState<number>(3);
    const [lengthOfProduct, setLengthOfProduct] = useState<number>(0);
    const [selectedMenuName, setSelectedMenuName] = useState<string>('');
    const [expanded, setExpanded] = React.useState<string | false>('panel1');
    const prevScrollY = useRef(0);
    const [goingUp, setGoingUp] = useState(false);

    useEffect(() => {
      if (!ProductStore.list?.length) {
        ProductStore.setProduct('DESC');
      }
      if (!ProductStore?.menu?.length) {
        ProductStore.setSortByProperties();
      }
    }, []);

    useEffect(() => {
      const handleScroll = () => {
        const currentScrollY = window.scrollY;
        if (prevScrollY.current < currentScrollY && goingUp) {
          setGoingUp(false);
        }
        if (prevScrollY.current > currentScrollY && !goingUp) {
          setGoingUp(true);
        }

        prevScrollY.current = currentScrollY;
        if (
          prevScrollY.current >= 300 &&
          goingUp === false &&
          (endOfProduct <= filterProducts.length ||
            mobileLoadMore <= filterProducts.length)
        ) {
          loadMore();
          setMobileLoadMore(mobileLoadMore + 6);
        }
      };

      window.addEventListener('scroll', handleScroll, { passive: true });
      return () => window.removeEventListener('scroll', handleScroll);
    }, [goingUp, filterProducts.length, endOfProduct, mobileLoadMore]);

    useEffect(() => {
      if (ProductStore.list?.length && ProductStore.menu?.length) {
        const { order_by_id } = ouayUnStore;
        const found = sortBy.find((ele: any) => ele.id == order_by_id);
        const nameTh = subStringSoryBy(found?.name_th);
        const nameEn = subStringSoryBy(found?.name_en);
        const menu = ProductStore.menu?.filter(
          (attr: any) => attr.sort_by_id === order_by_id,
        );

        setSelectedSortBy({
          ...found,
          name_en: nameEn,
          name_th: nameTh,
        });
        setMenu(menu);

        handleProducts(menu[0], found.id);
      }
    }, [ouayUnStore.order_by_id, i18next.language, ProductStore.list?.length, ProductStore.menu?.length]);

    const Accordion = withStyles({
      root: {
        borderTop: `1px solid ${color.mainColor4}`,
        boxShadow: 'none',
        '&:not(:last-child)': {
          borderBottom: 0,
        },
        '&:before': {
          display: 'none',
        },
        '&$expanded': {
          margin: 'auto',
        },
      },
      expanded: {},
    })(MuiAccordion);

    const AccordionSummary = withStyles({
      root: {
        backgroundColor: color.backgroundPrimary,
        borderBottom: `1px solid ${color.mainColor4}`,
        marginBottom: -1,
        height: 40,
        '&$expanded': {
          height: 48,
          minHeight: 48
        },
      },
      content: {
        '&$expanded': {
          margin: '12px 0',
        },
      },
      expanded: {},
    })(MuiAccordionSummary);

    const handleSortBy = (item: SortByProps) => {
      const nameTh = subStringSoryBy(item.name_th);
      const nameEn = subStringSoryBy(item.name_en);

      const menu = ProductStore.menu?.filter(
        (attr: any) => attr.sort_by_id === item.id,
      );

      //? เลือก order_by เดิมอันมั้ย?
      let selected_menu = menu[0];
      if (selectedSortBy.id === item.id && selectedMenu) {
        const found_menu = menu.find((m: any) => selectedMenu == +m.id);
        if (found_menu) selected_menu = found_menu;
      }

      setSelectedSortBy({
        ...item,
        name_en: nameEn,
        name_th: nameTh,
      });
      setMenu(menu);

      handleProducts(selected_menu, item.id);
    };

    const handleMenu = (id: number) => {
      const menuSelected = menu.filter((m: any) => +m.id === +id)[0];
      const sortById = selectedSortBy.id;

      handleProducts(menuSelected, sortById);
    };

    const handleProducts = (
      selected_menu: any,
      sort_by_id: number | string,
    ) => {
      setSelectedMenu(selected_menu.id);
      setSelectedMenuName(selected_menu[`name_${i18next.language}`]);

      const sortByName = +sort_by_id == 1 ? 'brand_id' : 'medicine_type_id';

      const filterProduct = ProductStore.list?.filter(
        (product: any) => +product[sortByName] === +selected_menu.id,
      );

      const products = filterProduct.reduce(function (rows, key, index) {
        return (
          (index % 3 == 0
            ? rows.push([key])
            : rows[rows.length - 1].push(key)) && rows
        );
      }, []);
      setFilterProducts(filterProduct);
      setLengthOfProduct(filterProduct.length);
      setProducts(products);
      setEndOfProduct(3);
    };

    const loadMore = () => {
      setEndOfProduct((prevState: number) => (prevState += 3));
    };

    const handleChange = (panel: string) => (
      event: React.ChangeEvent<{}>,
      newExpanded: boolean,
    ) => {
      setExpanded(newExpanded ? panel : false);
    };

    const BUTTON_GROUP: CSSProperties = {
      ...(i18next?.language === 'th' ? BODY_2_TH : BODY_2_EN),
      ...NON_PADDING_LEFT_RIGHT,
      border: 'none',
      display: 'flex',
      justifyContent: 'flex-start',
      textAlign: 'left',
    };

    const BUTTON: CSSProperties = {
      ...BUTTON_STYLE,
      fontFamily: i18next?.language === 'th' ? 'KanitLight' : 'RobotaRegular',
    };

    const edges = data.allFile.edges;

    return (
      <main className={'main'}>
        {/* //? for screen size xs-md */}
        <section className="d-md-none d-block" style={{ paddingTop: 40 }}>
          <Container fluid>
            <Row className="justify-content-center">
              <Col xs="10">
                <div
                  className="col-sm-header"
                  dangerouslySetInnerHTML={{ __html: t('our_product') }}
                  style={
                    i18next?.language === 'th'
                      ? MOBILE_HEADER_TH
                      : MOBILE_HEADER_EN
                  }
                />
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs="10" style={{ marginTop: spacing[5] }}>
                <Accordion
                  square
                  expanded={expanded === 'panel3'}
                // onChange={handleChange('panel3')}
                >
                  <AccordionSummary
                    aria-controls="panel3d-content"
                    id="panel3d-header"
                  >
                    {sortBy.map((item: SortByProps, index: number) => {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'center',
                          }}
                          key={index}
                        >
                          <Button
                            style={{
                              ...(i18next?.language === 'th' ? BODY_2_TH : BODY_2_EN),
                              ...(index === 0 ? {
                                paddingRight: '1.9rem'
                              } : {
                                paddingLeft: '1.9rem'
                              }),
                              fontFamily:
                                selectedSortBy?.id === item.id
                                  ? i18next?.language === 'th'
                                    ? 'KanitMedium'
                                    : 'RobotaBold'
                                  : i18next?.language === 'th'
                                    ? 'KanitLight'
                                    : 'RobotaRegular',
                            }}
                            onClick={() => {
                              setExpanded('panel3');
                              handleSortBy(item);
                            }}
                          >
                            {item[`name_${i18next.language}`]}
                          </Button>
                        </div>
                      );
                    })}
                  </AccordionSummary>
                  <div style={AccordionDetail}>
                    <ButtonGroup
                      orientation="vertical"
                      aria-label="vertical contained primary button group"
                      variant="text"
                      style={{
                        width: '100%',
                        borderTop: `1px solid ${color.mainColor4}`,
                        borderRadius: 0,
                      }}
                    >
                      {menu.map((item: any, index: number) => {
                        return (
                          <Button
                            style={{
                              padding: '12px 16px',
                              ...(i18next?.language === 'th' ? BODY_2_TH : BODY_2_EN),
                              fontFamily:
                                selectedMenu === item.id
                                  ? i18next?.language === 'th'
                                    ? 'KanitMedium'
                                    : 'RobotaBold'
                                  : i18next?.language === 'th'
                                    ? 'KanitLight'
                                    : 'RobotaRegular',
                            }}
                            key={index}
                            onClick={() => {
                              handleMenu(item.id);
                              setMobileLoadMore(6);
                              setExpanded(false);
                            }}
                          >
                            {item[`name_${i18next.language}`]}
                          </Button>
                        );
                      })}
                    </ButtonGroup>
                    <IconButton
                      aria-label="delete"
                      onClick={() => setExpanded(false)}
                    >
                      <Icon
                        icon={androidClose}
                        size={25}
                        style={{ color: '#dfdfdf' }}
                      />
                    </IconButton>
                  </div>
                </Accordion>
              </Col>
            </Row>
            {expanded === 'panel3' && <div style={{ height: spacing[9] }} />}
            <Container
              fluid
              style={{
                paddingTop: 15,
                display: expanded === 'panel3' ? 'none' : undefined,
              }}
            >
              <Row>
                {filterProducts
                  .slice(0, mobileLoadMore)
                  .map((product: any, index: number) => {
                    return (
                      <Col key={index} xs="6" style={{ padding: 15 }}>
                        <Link
                          to={`/product/${product.product_code}`}
                          style={LINK_TO}
                          state={{
                            productType: selectedMenuName,
                            selectedMenuId: selectedMenu,
                            sortByName:
                              selectedMenu == 1
                                ? 'brand_id'
                                : 'medicine_type_id',
                          }}
                        >
                          <CardCaption
                            source={getSourceImage(
                              product?.product_image_path_1
                                ? product.product_image_path_1
                                : 'no-img.png',
                              edges,
                            )}
                            containerStyle={{
                              borderRadius: '40px 0px',
                              background: product?.theme_code
                                ? `linear-gradient(180deg, ${palette.productSet1[
                                `${product.theme_code}1`
                                ]
                                } 60%, ${palette.productSet1[
                                `${product.theme_code}2`
                                ]
                                } 40%)`
                                : 'linear-gradient(180deg, #dbd9d4 60%, #e7e5e0 40%)',
                            }}
                            imageStyle={{
                              left: 'auto',
                            }}
                            disableAos
                            topic={product[`product_name_${i18next.language}`]}
                            content={
                              product[`details_paragraph_1_${i18next.language}`]
                            }
                            topicStyle={{
                              ...(i18next.language === 'th'
                                ? BODY_3_TH
                                : BODY_3_EN),
                              marginBottom: spacing[1],
                            }}
                            contentStyle={{
                              flex: 1,
                              paddingBottom: 5,
                              ...(i18next.language === 'th'
                                ? BODY_7_TH
                                : BODY_7_EN),
                            }}
                          />
                        </Link>
                      </Col>
                    );
                  })}
                <div style={{ ...LINE, margin: '30px 15px 0px' }} />
              </Row>
            </Container>
          </Container>
        </section>

        {/* //? for screen size md++ */}
        <section className="d-none d-md-block" style={{ paddingTop: 60 }}>
          <Container fluid>
            <Row className="justify-content-center">
              <Col md="10" style={{ maxWidth: 1080 }}>
                <Container fluid style={NON_PADDING_LEFT_RIGHT}>
                  <Row>
                    <Col xs="12">
                      <div
                        dangerouslySetInnerHTML={{ __html: t('our_product') }}
                        style={
                          i18next?.language === 'th' ? HEADER_TH : HEADER_EN
                        }
                      />
                      <hr
                        style={{ borderTop: `1.5px solid ${color.mainColor4}` }}
                      />
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: spacing[7] }}>
                    <Col xs="3">
                      <Container fluid style={NON_PADDING_LEFT_RIGHT}>
                        <Row>
                          <Col xs="12" style={{ paddingBottom: spacing[2] }}>
                            <span
                              style={
                                i18next?.language === 'th'
                                  ? TITLE_2_TH
                                  : TITLE_2_EN
                              }
                            >
                              {t('sort_by')}
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12">
                            <ButtonGroup
                              orientation="vertical"
                              aria-label="vertical contained primary button group"
                              variant="text"
                            >
                              {sortBy.map(
                                (item: SortByProps, index: number) => {
                                  return (
                                    <Button
                                      style={{
                                        ...BUTTON_GROUP,
                                        color:
                                          selectedSortBy?.id === item.id
                                            ? color.mainColor3
                                            : color.textBlack,
                                        fontFamily:
                                          selectedSortBy?.id === item.id
                                            ? i18next?.language === 'th'
                                              ? 'KanitMedium'
                                              : 'RobotaBold'
                                            : i18next?.language === 'th'
                                              ? 'KanitLight'
                                              : 'RobotaRegular',
                                      }}
                                      key={index}
                                      onClick={() => handleSortBy(item)}
                                    >
                                      {item[`name_${i18next.language}`]}
                                    </Button>
                                  );
                                },
                              )}
                            </ButtonGroup>
                          </Col>
                        </Row>
                        <br />
                        <br />
                        <Row>
                          <Col xs="12" style={{ paddingBottom: spacing[2] }}>
                            <span
                              style={
                                i18next?.language === 'th'
                                  ? TITLE_2_TH
                                  : TITLE_2_EN
                              }
                            >
                              {i18next?.language === 'th'
                                ? selectedSortBy?.name_th
                                : selectedSortBy?.name_en}
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12">
                            <ButtonGroup
                              orientation="vertical"
                              aria-label="vertical contained primary button group"
                              variant="text"
                            >
                              {menu.map((item: any, index: number) => {
                                return (
                                  <Button
                                    style={{
                                      ...BUTTON_GROUP,
                                      color:
                                        selectedMenu === item.id
                                          ? color.mainColor3
                                          : color.textBlack,
                                      fontFamily:
                                        selectedMenu === item.id
                                          ? i18next?.language === 'th'
                                            ? 'KanitMedium'
                                            : 'RobotaBold'
                                          : i18next?.language === 'th'
                                            ? 'KanitLight'
                                            : 'RobotaRegular',
                                    }}
                                    key={index}
                                    onClick={() => handleMenu(item.id)}
                                  >
                                    {item[`name_${i18next.language}`]}
                                  </Button>
                                );
                              })}
                            </ButtonGroup>
                          </Col>
                        </Row>
                      </Container>
                    </Col>
                    <Col xs="9">
                      <Container fluid style={NON_PADDING_LEFT_RIGHT}>
                        {products
                          .slice(0, endOfProduct)
                          .map((childProduct: any, index: number) => {
                            return (
                              <Row
                                key={index}
                                style={ROW_CARD_BOTTOM}
                                data-aos={'fade-up'}
                              >
                                {childProduct.map((product: any, i: number) => {
                                  return (
                                    <Col key={i} md={4}>
                                      <Link
                                        to={`/product/${product.product_code}`}
                                        style={LINK_TO}
                                        state={{
                                          productType: selectedMenuName,
                                          selectedMenuId: selectedMenu,
                                          sortByName:
                                            selectedMenu == 1
                                              ? 'brand_id'
                                              : 'medicine_type_id',
                                        }}
                                      >
                                        <CardCaption
                                          source={getSourceImage(
                                            product?.product_image_path_1
                                              ? product.product_image_path_1
                                              : 'no-img.png',
                                            edges,
                                          )}
                                          containerStyle={{
                                            borderRadius: '80px 0px',
                                            background: product?.theme_code
                                              ? `linear-gradient(180deg, ${palette.productSet1[
                                              `${product.theme_code}1`
                                              ]
                                              } 60%, ${palette.productSet1[
                                              `${product.theme_code}2`
                                              ]
                                              } 40%)`
                                              : 'linear-gradient(180deg, #dbd9d4 60%, #e7e5e0 40%)',
                                          }}
                                          disableAos
                                          topic={
                                            product[
                                            `product_name_${i18next.language}`
                                            ]
                                          }
                                          content={
                                            product[
                                            `details_paragraph_1_${i18next.language}`
                                            ]
                                          }
                                          topicStyle={{
                                            ...(i18next.language === 'th'
                                              ? BODY_3_TH
                                              : BODY_3_EN),
                                            marginBottom: 6,
                                          }}
                                          contentStyle={{
                                            flex: 1,
                                            paddingBottom: 5,
                                            ...(i18next.language === 'th'
                                              ? BODY_7_TH
                                              : BODY_7_EN),
                                          }}
                                        />
                                      </Link>
                                    </Col>
                                  );
                                })}
                                <div
                                  style={{ ...LINE, margin: '40px 15px 0px' }}
                                />
                              </Row>
                            );
                          })}

                        {/* {lengthOfProduct > 9 &&
                        endOfProduct * 3 < lengthOfProduct && (
                          <div style={BUTTON_CONTAINER} data-aos={'fade-up'}>
                            <button style={BUTTON} onClick={loadMore}>
                              {t('load_more')}
                            </button>
                          </div>
                        )} */}
                      </Container>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </section>

        <SpaceBetweenContent />
        <EndingContent />

        <SpaceBetweenContent className="d-md-none d-block" />
      </main>
    );
  },
);
export default Product;

export const query = graphql`
  query {
    allFile(filter: { relativePath: { regex: "/product/" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 335, quality: 100) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
