import { brands, medicineTypes, products } from '@/db/products';
import { types, flow, cast } from 'mobx-state-tree';

const ProductModel = types.model({
  product_id: types.union(types.number, types.string),
  remark: types.maybeNull(types.string),
  brand_id: types.maybeNull(types.string),
  medicine_type_id: types.maybeNull(types.string),
  properties_id: types.maybeNull(types.string),
  product_code: types.maybeNull(types.string),
  product_highLight: types.union(types.string, types.number),
  drug_registration_number: types.maybeNull(types.string),
  product_name_th: types.maybeNull(types.string),
  product_name_en: types.maybeNull(types.string),
  details_paragraph_1_th: types.maybeNull(types.string),
  details_paragraph_1_en: types.maybeNull(types.string),
  full_details_th: types.maybeNull(types.string),
  full_details_en: types.maybeNull(types.string),
  component_1_th: types.maybeNull(types.string),
  component_2_th: types.maybeNull(types.string),
  component_3_th: types.maybeNull(types.string),
  component_4_th: types.maybeNull(types.string),
  component_5_th: types.maybeNull(types.string),
  component_6_th: types.maybeNull(types.string),
  component_1_en: types.maybeNull(types.string),
  component_2_en: types.maybeNull(types.string),
  component_3_en: types.maybeNull(types.string),
  component_4_en: types.maybeNull(types.string),
  component_5_en: types.maybeNull(types.string),
  component_6_en: types.maybeNull(types.string),
  how_to_use_th: types.maybeNull(types.string),
  how_to_use_en: types.maybeNull(types.string),
  order_eating_id: types.maybeNull(types.string),
  morning_time: types.maybeNull(types.string),
  day_time: types.maybeNull(types.string),
  evening_time: types.maybeNull(types.string),
  time_before_bedtime: types.maybeNull(types.string),
  symptoms_worn: types.maybeNull(types.string),
  additional_help_th: types.maybeNull(types.string),
  additional_help_en: types.maybeNull(types.string),
  related_product_code_1: types.maybeNull(types.string),
  related_product_code_2: types.maybeNull(types.string),
  related_product_code_3: types.maybeNull(types.string),
  related_product_code_4: types.maybeNull(types.string),
  url_cart: types.maybeNull(types.string),
  product_image_path_1: types.maybeNull(types.string),
  product_image_path_2: types.maybeNull(types.string),
  product_image_path_3: types.maybeNull(types.string),
  ingredient_image_path: types.maybeNull(types.string),
  detail_background_color: types.maybeNull(types.string),
  component_backgroud_color: types.maybeNull(types.string),
  how_to_use_background_color: types.maybeNull(types.string),
  theme_code: types.maybeNull(types.string),
});

const MenuModel = types.model({
  id: types.union(types.number, types.string),
  name_th: types.maybeNull(types.string),
  name_en: types.maybeNull(types.string),
  sort_by_id: types.union(types.number, types.string),
})

export const ProductStore = types
  .model('ProductStore', {
    list: types.array(types.maybeNull(ProductModel)),
    menu: types.array(types.maybeNull(MenuModel)),
    loading: types.boolean,
    error: types.maybeNull(types.string),
  })
  .actions((self) => {
    return {
      setProduct(sort?: 'ASC' | 'DESC') {
        let result: any = [];
        if (sort === 'ASC') {
          result = products.sort(
            (a, b) =>
              (+a.product_code > +b.product_code) ? 1 :
                ((+b.product_code > +a.product_code) ? -1 : 0)
          );
        } else {
          result = products.sort(
            (a, b) =>
              (+a.product_code < +b.product_code) ? 1 :
                ((+b.product_code < +a.product_code) ? -1 : 0)
          );
        }
        self.list = result;
      },

      setSortByProperties() {
        const menu = [...brands, ...medicineTypes].map(
          (item) => ({
            id: item.id,
            name_th: item.name_th,
            name_en: item.name_en.toUpperCase(),
            sort_by_id: item.sort_by_id,
          }),
        );
        self.menu = cast(menu);
      }
    };
  })
  .create({
    list: [],
    menu: [],
    loading: false,
    error: null,
  });
